import {
  faPencilAlt,
  faTimes,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import Confirm from "../Util/Confirm";
import ConfirmSuccess from "../Util/ConfirmSuccess";
import axios from "axios";

export default function SelectedReview(props) {
  const [clicked, setClicked] = useState(false);
  const [toggleBasic, setToggleBasic] = useState(false);
  const [toggleperformance, setTogglePerformance] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [kpis, setKPIs] = useState(null);
  const [active, setActive] = useState("Org Details");
  const [koffset, setKOffset] = useState(0);
  const [offset, setOffset] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [singleApplication, setSingleApplication] = useState(false);

  function deleteApplication() {
    props.setLoading(true);
    fetch(`/api/${props.url}/${props?.beneficiaryDetails?.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
        setTimeout(() => {
          props.setRefresh(!props.refresh);
        }, 5000);
        setShowSuccess(true);
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const closeSuccess = () => {
    setShowSuccess(false);
  };

  const fetchAndOpenCL = async () => {
    try {
      const response = await axios.get(
        `/api/uploads/${props?.beneficiaryDetails?.File}`,
        {
          responseType: "blob",
        }
      );

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );

      // Open the URL in a new tab
      window.open(url, "_blank", "noopener noreferrer");

      // Revoke the object URL after some time to release memory
      setTimeout(() => window.URL.revokeObjectURL(url), 1000);
    } catch (error) {
      console.error("Error fetching Cover Letter:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="sbeneficiary">
      <div>
        <p>Student Name: {props?.beneficiaryDetails?.StudentName}</p>
        <p>Student Email: {props?.beneficiaryDetails?.StudentEmail}</p>
        <p>Date Reviewed: {formatDate(props?.beneficiaryDetails?.updatedAt)}</p>
        <hr />
        <p>
          Review File:
          <a href="#" onClick={fetchAndOpenCL}>
            File
          </a>
        </p>
        <br />
      </div>
      <hr />
      <div className="data">
        <div className="bars">
          <h4
            onClick={() => {
              setActive("Update Details");
              setToggleBasic(true);
            }}
            className={active === "Edit" ? "active" : ""}
          >
            Edit
          </h4>

          <h4 onClick={() => {}} style={{ color: "red" }}>
            Delete
          </h4>
        </div>

        <hr />
      </div>

      {toggleBasic && (
        <UpdateApplication
          setToggleBasic={setToggleBasic}
          setRefresh={setRefresh}
          refresh={refresh}
          setSingleApplication={setSingleApplication}
          singleApplication={singleApplication}
          beneficiaryDetails={props.beneficiaryDetails}
        />
      )}
      {showConfirm && (
        <Confirm
          closeConfirm={closeConfirm}
          deleteFunction={deleteApplication}
          message="you want to delete?"
          action="delete"
        />
      )}
      {showSuccess && (
        <ConfirmSuccess closeConfirm={closeSuccess} action="deleted" />
      )}
    </div>
  );
}

const UpdateApplication = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedCL, setSelectedCL] = useState(null);
  const [selectedCV, setSelectedCV] = useState(null);
  const cpositions = ["Internship", "Attachment"];
  const companies = ["Company1", "Company2"];

  const studentName = useRef();
  const studentEmail = useRef();
  const studentID = useRef();
  const companyID = useRef();
  const cl = useRef();
  const cv = useRef();
  const position = useRef();
  const course = useRef();
  const companyName = useRef();
  const vacancy = useRef();

  const [body, updateBody] = useState({
    StudentName: null,
    StudentID: null,
    StudentStudyArea: null,
    CompanyID: null,
    StudentEmail: null,
    CompanyName: null,
    Course: null,
    Position: null,
    Vacancy: null,
    CL: null,
    CV: null,
  });

  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const submitApplication = (e) => {
    setError("");
    let d = body;
    d.StudentName = props.details?.StudentName;
    d.StudentEmail = props.details?.StudentEmail;
    d.StudentID = props.details?.StudentID;
    d.Position = position.current.value;
    d.Vacancy = vacancy.current.value;
    d.Course = props.details?.StudentStudyArea;
    d.CompanyName = props.beneficiaryDetails?.Name;
    d.CompanyID = props.beneficiaryDetails?.ID;
    d.CL = cl.current.files[0];
    d.CV = cv.current.files[0];
    updateBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    if (d.CV === undefined) return setError("CV is required!");
    setLoading(true);

    const formData = new FormData();

    for (const i in body) {
      formData.append(i, body[i]);
    }

    fetch("/api/applications/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setClicked(false);
          setTimeout(() => {}, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  useEffect(() => {
    const fetchSingleApplication = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `/api/applications/${props?.beneficiaryDetails?.ID}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data.");
        }
        const data = await response.json();
       
        props.setSingleApplication(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSingleApplication();
  }, []);

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>Update Application</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setToggleBasic(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Select
              ref={companyName}
              data={[" ", "Attachment", "Internship"]}
              label="Select Company"
              value={props?.beneficiaryDetails?.CompanyName}
            />
            <Select
              ref={position}
              data={[" ", "Attachment", "Internship"]}
              label="Select Position"
              value={props?.beneficiaryDetails?.Position}
            />
            <Select
              ref={vacancy}
              data={[" ", "YES", "NO"]}
              label="Select the Vacancy for the position you've chosen above"
              value={props?.beneficiaryDetails?.Vacancy}
            />
            <div className="usrinput">
              <h4>Change Cover Letter *</h4>
              <input
                ref={cl}
                type="file"
                label="Upload file *"
                onChange={(e) => setSelectedCL(e.target.files[0])}
                accept=".pdf"
              />
            </div>
            <div className="usrinput">
              <h4>Change Curriculum Vitae</h4>
              <input
                ref={cv}
                type="file"
                label="Upload file *"
                onChange={(e) => setSelectedCV(e.target.files[0])}
                accept=".pdf"
              />
            </div>

            <p>{error}</p>
            <Button handleClick={submitApplication} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
