import React, { forwardRef } from "react";

const CompaniesSelect = (props, ref) => {
  return (
    <div className="usrselect">
      <h4 htmlFor="input">{props.label}</h4>
      <select
        onChange={(e) => {
          props.handleSelection(e.target.value);
        }}
        ref={ref}
        value={props.value}
      >
        {Array.isArray(props.data) && // Check if props.data is an array
          props.data.map((item, index) => {
            return (
              <option key={index} value={item.Company}>
                {item.Company}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default forwardRef(CompaniesSelect);
