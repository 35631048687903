import {
  faPencilAlt,
  faTimes,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import Confirm from "../Util/Confirm";
import ConfirmSuccess from "../Util/ConfirmSuccess";
import axios from "axios";

export default function SelectedReview(props) {
  const [clicked, setClicked] = useState(false);
  const [toggleBasic, setToggleBasic] = useState(false);
  const [toggleperformance, setTogglePerformance] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [kpis, setKPIs] = useState(null);
  const [active, setActive] = useState("Org Details");
  const [koffset, setKOffset] = useState(0);
  const [offset, setOffset] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [singleApplication, setSingleApplication] = useState(false);

  function deleteApplication() {
    props.setLoading(true);
    fetch(`/api/${props.url}/${props?.beneficiaryDetails?.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
        setTimeout(() => {
          props.setRefresh(!props.refresh);
        }, 5000);
        setShowSuccess(true);
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const closeSuccess = () => {
    setShowSuccess(false);
  };

  const fetchAndOpenCL = async () => {
    try {
      const response = await axios.get(
        `/api/uploads/${props?.beneficiaryDetails?.File}`,
        {
          responseType: "blob",
        }
      );

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );

      // Open the URL in a new tab
      window.open(url, "_blank", "noopener noreferrer");

      // Revoke the object URL after some time to release memory
      setTimeout(() => window.URL.revokeObjectURL(url), 1000);
    } catch (error) {
      console.error("Error fetching Cover Letter:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  console.log(props?.beneficiaryDetails);

  return (
    <div className="sbeneficiary">
      <div>
        <p>Student Name: {props?.beneficiaryDetails?.StudentName}</p>
        <p>Student Email: {props?.beneficiaryDetails?.StudentEmail}</p>
        <p>Date Reviewed: {formatDate(props?.beneficiaryDetails?.updatedAt)}</p>
        <hr />
        <p style={{ fontWeight: "bold", fontSize: "normal" }}>
          Supervisor Remarks: {props?.beneficiaryDetails?.SupervisorRemarks}
        </p>
        <p style={{ fontWeight: "bold", fontSize: "small" }}>
          Coordinator Remarks:{" "}
          {props?.beneficiaryDetails?.CoordinatorRemarks
            ? props?.beneficiaryDetails?.CoordinatorRemarks
            : "Pending Review"}
        </p>
        <hr />
        <p>
          Review File:
          <a href="#" onClick={fetchAndOpenCL}>
            File
          </a>
        </p>
        <br />
      </div>
      <hr />
      {props.role === "Company" && (
        <div className="data">
          <div className="bars">
            <h4
              onClick={() => {
                setActive("Update Details");
                setToggleBasic(true);
              }}
              className={active === "Edit" ? "active" : ""}
            >
              Edit
            </h4>

            <h4 onClick={() => {}} style={{ color: "red" }}>
              Delete
            </h4>
          </div>

          <hr />
        </div>
      )}

      {props.role === "Coordinator" && (
        <div className="data">
          <div className="bars">
            <h4
              onClick={() => {
                setActive("Add Remarks");
                setToggleBasic(true);
              }}
              className={active === "Add Remarks" ? "active" : ""}
            >
              Add Remarks
            </h4>
          </div>

          <hr />
        </div>
      )}

      {props.role === "Company" && toggleBasic && (
        <SupervisorRemarks
          setToggleBasic={setToggleBasic}
          setRefresh={setRefresh}
          refresh={refresh}
          setSingleApplication={setSingleApplication}
          singleApplication={singleApplication}
          beneficiaryDetails={props.beneficiaryDetails}
        />
      )}
      {props.role === "Coordinator" && toggleBasic && (
        <CoordinatorRemarks
          setToggleBasic={setToggleBasic}
          setRefresh={setRefresh}
          refresh={refresh}
          setSingleApplication={setSingleApplication}
          singleApplication={singleApplication}
          beneficiaryDetails={props.beneficiaryDetails}
        />
      )}
      {showConfirm && (
        <Confirm
          closeConfirm={closeConfirm}
          deleteFunction={deleteApplication}
          message="you want to delete?"
          action="delete"
        />
      )}
      {showSuccess && (
        <ConfirmSuccess closeConfirm={closeSuccess} action="deleted" />
      )}
    </div>
  );
}

const SupervisorRemarks = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const coordRemarks = useRef();
  const date = useRef();
  const file = useRef();

  const [body, updateBody] = useState({
    CoordinatorRemarks: props?.beneficiaryDetails?.CoordinatorRemarks,
    Date: props?.beneficiaryDetails?.Date,
    File: props?.beneficiaryDetails?.File,
  });

  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const submitApplication = (e) => {
    setError("");
    let d = body;
    d.SupervisorRemarks = coordRemarks.current.value;
    d.Date = date.current.value;
    d.File = file.current.files[0];
    updateBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    setLoading(true);

    const formData = new FormData();

    for (const i in body) {
      formData.append(i, body[i]);
    }

    fetch(`/api/reviews/${props?.beneficiaryDetails?.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setToggleBasic(false);
          window.location.href = "/reviews";
          setTimeout(() => {}, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>Student Name: {props?.beneficiaryDetails?.StudentName}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setToggleBasic(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Input
              type="date"
              ref={date}
              label="Date of Review"
              value={props?.beneficiaryDetails?.Date}
            />

            <Input
              type="text"
              ref={coordRemarks}
              label="Supervisor Remarks"
              value={props?.beneficiaryDetails?.SupervisorRemarks}
            />

            <div className="usrinput">
              <h4>Change Review File *</h4>
              <input
                ref={file}
                type="file"
                label="Upload File *"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                accept=".pdf"
              />
            </div>

            <p>{error}</p>
            <Button handleClick={submitApplication} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};

const CoordinatorRemarks = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedCL, setSelectedCL] = useState(null);
  const [selectedCV, setSelectedCV] = useState(null);
  const cpositions = ["Internship", "Attachment"];
  const companies = ["Company1", "Company2"];

  const coordRemarks = useRef();

  const [body, updateBody] = useState({
    CoordinatorRemarks: null,
  });

  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const submitApplication = (e) => {
    setError("");
    let d = body;
    d.CoordinatorRemarks = coordRemarks.current.value;
    updateBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    setLoading(true);

    const formData = new FormData();

    for (const i in body) {
      formData.append(i, body[i]);
    }

    fetch(`/api/reviews/coordinator/${props?.beneficiaryDetails?.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setToggleBasic(false);
          setTimeout(() => {}, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>Student Name: {props?.beneficiaryDetails?.StudentName}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setToggleBasic(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Input ref={coordRemarks} label="Add Remarks" type="text" />

            <p>{error}</p>
            <Button handleClick={submitApplication} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
