import {
  faSearch,
  faTimes,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/beneficiary.scss";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import ApplicationBox from "./ReviewBox";
import SelectedApplication from "./SelectedReview";
import Success from "../Util/ConfirmSuccess";
import CompaniesSelect from "../Util/CompaniesSelect";
import VacanciesSelect from "../Util/VacanciesSelect";
import VacTypeSelect from "../Util/VacTypeSelect";
import StudentSelect from "../Util/StudentSelect";

export default function Reviews(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [ID, setID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(null);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState(null);
  const selected = useRef();
  const [role, setRole] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const jwt = require("jsonwebtoken");
  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

  useEffect(() => {
    if (token) {
      const decoded = jwt.decode(token);
      setBeneficiaryDetails(decoded);
      setUserID(decoded.UserID);
      setRole(decoded.Role);
    }
  }, [token]);

  useEffect(() => {
    setLoading(true);
    if (role === "Company") {
      fetch(`/api/reviews/company/${userID}/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          setLoading(false);
          setData(data);
          setBeneficiaryDetails(data.data[0]);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      fetch(`/api/reviews/paginated/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          setLoading(false);
          setData(data);
          setBeneficiaryDetails(data.data[0]);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [refresh, offset, userID]);

  return (
    <div className="beneficiary">
      <div className="list">
        <div className="utp">
          <h3>Student Reviews</h3>
          {role === "Company" && (
            <p
              onClick={() => {
                setClicked(true);
              }}
            >
              <FontAwesomeIcon className="fa-add" icon={faUserPlus} /> New
              Review
            </p>
          )}
        </div>
        <hr />

        {isMobile ? (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => {
                      return (
                        <ApplicationBox
                          key={index}
                          item={item}
                          ID={ID}
                          setID={setID}
                          setBeneficiaryDetails={setBeneficiaryDetails}
                          selected={selected}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <div>
                  <h4>Beneficiary Details</h4>
                  <hr />
                </div>

                {beneficiaryDetails ? (
                  beneficiaryDetails && (
                    <SelectedApplication
                      setLoading={setLoading}
                      beneficiaryDetails={beneficiaryDetails}
                      setRefresh={setRefresh}
                      userID={userID}
                      refresh={refresh}
                      url="waterproviders"
                      role={role}
                    />
                  )
                ) : (
                  <>
                    <p>Click on a beneficiary to see their details</p>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => {
                      return (
                        <ApplicationBox
                          key={index}
                          item={item}
                          ID={ID}
                          setID={setID}
                          setBeneficiaryDetails={setBeneficiaryDetails}
                          selected={null}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <div>
                  <h4>Beneficiary Details</h4>
                  <hr />
                </div>

                {beneficiaryDetails ? (
                  beneficiaryDetails && (
                    <SelectedApplication
                      setLoading={setLoading}
                      beneficiaryDetails={beneficiaryDetails}
                      setRefresh={setRefresh}
                      userID={userID}
                      refresh={refresh}
                      url="waterproviders"
                      role={role}
                    />
                  )
                ) : (
                  <>
                    <p>Click on a beneficiary to see their details</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {loading && <Loading />}
      </div>
      {clicked && (
        <CreateReview
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          beneficiaryDetails={beneficiaryDetails}
          userID={userID}
        />
      )}
    </div>
  );
}

const CreateReview = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const date = useRef();
  const sname = useRef();
  const sremarks = useRef();
  const file = useRef();
  const [studentIDMap, setStudentIDMap] = useState({});
  const [studentDetails, setStudentDetails] = useState(null);
  const [selectedStudentID, setSelectedStudentID] = useState();
  const [fetchStudents, setFetchStudents] = useState();

  const [body, updateBody] = useState({
    CompanyID: props?.userID,
    StudentID: null,
    Date: null,
    SupervisorRemarks: null,
    File: null,
  });

  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const handleStudentChange = (value) => {
    const updateStudentIDMap = studentIDMap[value];
    setSelectedStudentID(updateStudentIDMap);
  };

  useEffect(() => {
    setLoading(true);
    fetch(`/api/student`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        const idMap = {};
        data.forEach((student) => {
          idMap[student.StudentName] = student.StudentID;
        });
        setStudentIDMap(idMap);
        setFetchStudents(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/reviews/paginated/0`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setStudentDetails(data[0]);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const submitReview = (e) => {
    let d = body;
    d.CompanyID = props?.userID;
    d.StudentID = selectedStudentID;
    d.Date = date.current.value;
    d.SupervisorRemarks = sremarks.current.value;
    d.File = file.current.files[0];
    updateBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    setLoading(true);

    const formData = new FormData();

    for (const i in body) {
      formData.append(i, body[i]);
    }

    fetch("/api/reviews/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("Submission Failed");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setClicked(false);
          setTimeout(() => {}, 1000);
          window.location.href = "/reviews";
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props?.beneficiaryDetails?.Name}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <StudentSelect
              ref={sname}
              handleSelection={handleStudentChange}
              label="Select Student"
              data={fetchStudents}
            />

            <Input type="date" ref={date} label="Date of Review" />

            <Input type="text" ref={sremarks} label="Supervisor Remarks" />

            <div className="usrinput">
              <h4>Upload Review File *</h4>
              <input
                ref={file}
                type="file"
                label="Upload File *"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                accept=".pdf"
              />
            </div>

            <p>{error}</p>
            <Button handleClick={submitReview} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
