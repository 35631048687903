import {
  faPencilAlt,
  faTimes,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import Confirm from "../Util/Confirm";
import ConfirmSuccess from "../Util/ConfirmSuccess";
import VacanciesSelect from "../Util/VacanciesSelect";
import VacTypeSelect from "../Util/VacTypeSelect";

export default function SelectedCompany(props) {
  const [clicked, setClicked] = useState(false);
  const [toggleBasic, setToggleBasic] = useState(false);
  const [toggleperformance, setTogglePerformance] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [kpis, setKPIs] = useState(null);
  const [active, setActive] = useState("Org Details");
  const [koffset, setKOffset] = useState(0);
  const [offset, setOffset] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    props.setLoading(true);
    fetch(`/api/student/fetch/${props.userID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        props.setLoading(false);
        setDetails(data[0]);
      })
      .catch((error) => {
        props.setLoading(false);
      });
  }, [props?.userID]);

  function deleteBeneficiary() {
    props.setLoading(true);
    fetch(`/api/${props.url}/${props?.beneficiaryDetails?.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
        setTimeout(() => {
          props.setRefresh(!props.refresh);
        }, 5000);
        setShowSuccess(true);
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  function deleteItem(url, id) {
    props.setLoading(true);
    fetch(`/api/${url}/${id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const openSucess = () => {
    setShowSuccess(true);
  };

  const closeSuccess = () => {
    setShowSuccess(false);
  };

  return (
    <div className="sbeneficiary">
      <div>
        <p>Name: {props?.beneficiaryDetails?.Name}</p>
        <p>County: {props?.beneficiaryDetails?.County}</p>
        <p>Address: {props?.beneficiaryDetails?.PhysicalAddress}</p>
        <p>Email: {props?.beneficiaryDetails?.Email}</p>
      </div>
      <hr />

      {/* {props.role === "Student" && (
        <div className="data">
          <div className="bars">
            <h4
              onClick={() => {
                setClicked(true);
              }}
              className={active === "Apply" ? "active" : ""}
            >
              <FontAwesomeIcon className="fa" icon={faPencilAlt} />
              Apply
            </h4>
          </div>
        </div>
      )} */}

      {clicked && (
        <MakeApplication
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          details={details}
          beneficiaryDetails={props.beneficiaryDetails}
        />
      )}

      {showConfirm && (
        <Confirm
          closeConfirm={closeConfirm}
          deleteFunction={deleteBeneficiary}
          message="you want to delete?"
          action="delete"
        />
      )}
      {showSuccess && (
        <ConfirmSuccess closeConfirm={closeSuccess} action="deleted" />
      )}
    </div>
  );
}

const MakeApplication = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedCL, setSelectedCL] = useState(null);
  const [selectedCV, setSelectedCV] = useState(null);
  const [fetchVacancies, setFetchVacancies] = useState();
  const [fetchVacType, setFetchVacType] = useState();

  const studentName = useRef();
  const studentEmail = useRef();
  const studentID = useRef();
  const companyID = useRef();
  const cl = useRef();
  const cv = useRef();
  const position = useRef();
  const course = useRef();
  const companyName = useRef();
  const vacancy = useRef();

  const [body, updateBody] = useState({
    StudentName: null,
    StudentID: null,
    StudentStudyArea: null,
    CompanyID: null,
    StudentEmail: null,
    CompanyName: null,
    Course: null,
    Position: null,
    Vacancy: null,
    CL: null,
    CV: null,
  });

  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const setVacancy = (value) => {
    // setCompanies(value)
  };

  const setVacancyType = (value) => {
    // setCompanies(value)
  };

  useEffect(() => {
    setLoading(true);
    fetch(`/api/vacancies/company/${props.beneficiaryDetails?.ID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        if (data.data.length === 0) {
          setError("No advertised vacancies!");
        } else {
          setFetchVacancies(data.data);
          setFetchVacType(data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [props.beneficiaryDetails?.ID]);

  const submitApplication = (e) => {
    setError("");
    let d = body;
    d.StudentName = props.details?.StudentName;
    d.StudentEmail = props.details?.StudentEmail;
    d.StudentID = props.details?.StudentID;
    d.Position = position.current.value;
    d.Vacancy = vacancy.current.value;
    d.Course = props.details?.StudentStudyArea;
    d.CompanyName = props.beneficiaryDetails?.Name;
    d.CompanyID = props.beneficiaryDetails?.ID;
    d.CL = cl.current.files[0];
    d.CV = cv.current.files[0];
    updateBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    if (d.CV === undefined) return setError("CV is required!");
    setLoading(true);

    const formData = new FormData();

    for (const i in body) {
      formData.append(i, body[i]);
    }

    fetch("/api/applications/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setClicked(false);
          setTimeout(() => {}, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props?.beneficiaryDetails?.Name}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <VacanciesSelect
              ref={vacancy}
              handleSelection={setVacancy}
              label="Select Vacancy"
              data={fetchVacancies}
            />

            <VacTypeSelect
              ref={position}
              handleSelection={setVacancyType}
              label="Select Position"
              data={fetchVacType}
            />

            <div className="usrinput">
              <h4>Upload Cover Letter *</h4>
              <input
                ref={cl}
                type="file"
                label="Upload file *"
                onChange={(e) => setSelectedCL(e.target.files[0])}
                accept=".pdf"
              />
            </div>
            <div className="usrinput">
              <h4>Upload Curriculum Vitae</h4>
              <input
                ref={cv}
                type="file"
                label="Upload file *"
                onChange={(e) => setSelectedCV(e.target.files[0])}
                accept=".pdf"
              />
            </div>

            <p>{error}</p>
            <Button handleClick={submitApplication} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
