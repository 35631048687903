import { useEffect, useState } from "react";

export default function ApplicationBox(props) {
  const [color, setColor] = useState("orange");

  useEffect(() => {
    if (props.item !== null) {
      switch (props?.item?.Position) {
        case "Internship":
          setColor("wheat");
          break;
        case "Attachment":
          setColor("light-blue");
          break;
        case "Job":
          setColor("red");
          break;
        default:
          break;
      }
    }
  }, [props.item]);

  return (
    <div
      className={props.ID === props.item.ID ? "user-box active" : "user-box"}
      onClick={() => {
        props.setID(props?.item?.ID);
        props.setBeneficiaryDetails(props?.item);
        props?.selected?.current.scrollIntoView({ behavior: "smooth" });
      }}
    >
      <div className="left">
        <h2 style={{ backgroundColor: color }}>{props?.item?.Position}</h2>
      </div>
      <div className="right">
        <h3 className="title">Name: {props?.item?.CompanyName}</h3>
        <h3 className="dark">
          Position:
          {props?.item?.Position}
        </h3>
        <h3 className="dark">
          Status:
          {props?.item?.Status}
        </h3>
      </div>
    </div>
  );
}
