import { useEffect, useState } from "react";

export default function ReviewBox(props) {
  const [color, setColor] = useState("orange");

  useEffect(() => {
    if (props.item !== null) {
      switch (props?.item?.Position) {
        case "Internship":
          setColor("wheat");
          break;
        case "Attachment":
          setColor("light-blue");
          break;
        case "Job":
          setColor("red");
          break;
        default:
          break;
      }
    }
  }, [props.item]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className={props.ID === props.item.ID ? "user-box active" : "user-box"}
      onClick={() => {
        props.setID(props?.item?.ID);
        props.setBeneficiaryDetails(props?.item);
        props?.selected?.current.scrollIntoView({ behavior: "smooth" });
      }}
    >
      <div className="left">
        <h2 style={{ backgroundColor: color }}>{props?.item?.StudentName}</h2>
      </div>
      <div className="right">
        <h3 className="title">Student: {props?.item?.StudentName}</h3>
        <h3 className="dark">
          Remarks:
          {props?.item?.SupervisorRemarks}
        </h3>
        <h3 className="dark">
          Date:
          {formatDate(props?.item?.createdAt)}
        </h3>
      </div>
    </div>
  );
}
