import React, { useEffect, useState } from "react";

const CLPDFViewer = ({ match }) => {
  const { id } = match.params;
  const [pdfURL, setPDFURL] = useState("");

  useEffect(() => {
    // Fetch CL PDF URL based on ID
    // Example: const pdfURL = fetchCLPDF(id);
    setPDFURL(`path/to/cl/${id}`);
  }, [id]);

  return (
    <div>
      <h2>Cover Letter PDF</h2>
      {/* Render PDF component or embed PDF viewer */}
      <embed src={pdfURL} width="800" height="600" />
    </div>
  );
};

export default CLPDFViewer;
