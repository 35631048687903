import React, { useEffect, useRef, useState } from "react";

export default function SelectedPlacement(props) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="sbeneficiary">
      <div>
        <p>Student Name: {props?.beneficiaryDetails?.StudentName}</p>
        <p>Student Email: {props?.beneficiaryDetails?.StudentEmail}</p>
        <p>Student Course: {props?.beneficiaryDetails?.Course}</p>
        <hr />
        <p>Company: {props?.beneficiaryDetails?.CompanyName}</p>
        <p>Title: {props?.beneficiaryDetails?.Vacancy}</p>
        <p>Position: {props?.beneficiaryDetails?.Position}</p>
        <hr />
        <p>Date Started: {formatDate(props?.beneficiaryDetails?.updatedAt)}</p>
        <p>Status: {props?.beneficiaryDetails?.Status}</p>
        <br />
      </div>
    </div>
  );
}
