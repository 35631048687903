import {
  faSearch,
  faTimes,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/beneficiary.scss";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import ApplicationBox from "./ApplicationBox";
import SelectedApplication from "./SelectedApplication";
import Success from "../Util/ConfirmSuccess";
import CompaniesSelect from "../Util/CompaniesSelect";
import VacanciesSelect from "../Util/VacanciesSelect";
import VacTypeSelect from "../Util/VacTypeSelect";

export default function StudentApplications(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [ID, setID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(null);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState(null);
  const selected = useRef();
  const [showConfirm, setShowConfirm] = useState(false);
  const jwt = require("jsonwebtoken");
  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

  useEffect(() => {
    if (token) {
      const decoded = jwt.decode(token);
      setDetails(decoded);
      setUserID(decoded.UserID);
    }
  }, [token]);

  useEffect(() => {
    const fetchApplications = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");
        if (!token) {
          throw new Error("Token not found.");
        }
        const decoded = jwt.decode(token);
        setUserID(decoded.UserID);

        const response = await fetch(
          `/api/applications/company/${decoded.UserID}/${offset * 12}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data.");
        }
        const data = await response.json();
        setData(data);
        setBeneficiaryDetails(data[0]);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [offset]);

  return (
    <div className="beneficiary">
      <div className="list">
        <div className="utp">
          <h3>Student Applications to {beneficiaryDetails?.Name} </h3>
        </div>
        <hr />

        {isMobile ? (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => {
                      return (
                        <ApplicationBox
                          key={index}
                          item={item}
                          ID={ID}
                          setID={setID}
                          setBeneficiaryDetails={setBeneficiaryDetails}
                          selected={selected}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <div>
                  <h4>Application Details</h4>
                  <hr />
                </div>

                {beneficiaryDetails ? (
                  beneficiaryDetails && (
                    <SelectedApplication
                      setLoading={setLoading}
                      beneficiaryDetails={beneficiaryDetails}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      url="waterproviders"
                    />
                  )
                ) : (
                  <>
                    <p>Click on a beneficiary to see their details</p>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => {
                      return (
                        <ApplicationBox
                          key={index}
                          item={item}
                          ID={ID}
                          setID={setID}
                          setBeneficiaryDetails={setBeneficiaryDetails}
                          selected={null}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <div>
                  <h4>Application Details</h4>
                  <hr />
                </div>

                {beneficiaryDetails ? (
                  beneficiaryDetails && (
                    <SelectedApplication
                      setLoading={setLoading}
                      beneficiaryDetails={beneficiaryDetails}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      url="waterproviders"
                    />
                  )
                ) : (
                  <>
                    <p>Click on a beneficiary to see their details</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {loading && <Loading />}
      </div>
      {clicked && (
        <MakeApplication
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          beneficiaryDetails={beneficiaryDetails}
          details={details}
        />
      )}
    </div>
  );
}

const MakeApplication = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedCL, setSelectedCL] = useState(null);
  const [selectedCV, setSelectedCV] = useState(null);
  const cpositions = ["Internship", "Attachment"];
  const companies = ["Company1", "Company2"];

  const studentName = useRef();
  const studentEmail = useRef();
  const studentID = useRef();
  const companyID = useRef();
  const cl = useRef();
  const cv = useRef();
  const position = useRef();
  const course = useRef();
  const companyName = useRef();
  const vacancy = useRef();
  const [fetchCompanies, setFetchcompanies] = useState();
  const [fetchVacancies, setFetchVacancies] = useState();
  const [fetchVacType, setFetchVacType] = useState();
  const [studentDetails, setStudentDetails] = useState();
  const [compDetails, setCompDetails] = useState();
  const [companyIDMap, setCompanyIDMap] = useState({});
  const [selectedCompanyID, setSelectedCompanyID] = useState();

  useEffect(() => {
    setLoading(true);
    fetch(`/api/vacancies`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        const idMap = {};
        data.forEach((company) => {
          idMap[company.Company] = company.CompanyID;
        });
        setCompanyIDMap(idMap);
        setFetchcompanies(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const handleCompanyChange = (value) => {
    const selectedCompanyID = companyIDMap[value];
    setSelectedCompanyID(selectedCompanyID);
  };

  const setVacancy = (value) => {
    // setCompanies(value)
  };

  const setVacancyType = (value) => {
    // setCompanies(value)
  };

  useEffect(() => {
    setLoading(true);
    fetch(`/api/student/fetch/${props.details.UserID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setStudentDetails(data[0]);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedCompanyID) {
      setLoading(true);
      fetch(`/api/vacancies/company/${selectedCompanyID}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          setLoading(false);
          setFetchVacancies(data.data);
          setFetchVacType(data.data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [selectedCompanyID]);

  const [body, updateBody] = useState({
    StudentName: null,
    StudentID: null,
    CompanyID: null,
    StudentEmail: null,
    CompanyName: null,
    Course: null,
    Position: null,
    Vacancy: null,
    CL: null,
    CV: null,
  });

  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const submitApplication = (e) => {
    setError("");
    let d = body;
    d.StudentName = studentDetails?.StudentName;
    d.StudentEmail = studentDetails?.StudentEmail;
    d.StudentID = studentDetails?.StudentID;
    d.Course = studentDetails?.StudentStudyArea;
    d.Position = position.current.value;
    d.Vacancy = vacancy.current.value;
    d.CompanyName = companyName.current.value;
    d.CompanyID = selectedCompanyID; // Correctly assign selectedCompanyID here
    d.CL = cl.current.files[0];
    d.CV = cv.current.files[0];
    updateBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    if (d.CV === undefined) return setError("CV is required!");
    setLoading(true);

    const formData = new FormData();

    for (const i in body) {
      formData.append(i, body[i]);
    }

    fetch("/api/applications/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setClicked(false);
          setTimeout(() => {}, 1000);
          window.location.href = "/applications";
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props?.beneficiaryDetails?.Name}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <CompaniesSelect
              ref={companyName}
              handleSelection={handleCompanyChange}
              label="Select Company"
              data={fetchCompanies}
            />

            <VacanciesSelect
              ref={vacancy}
              handleSelection={setVacancy}
              label="Select Vacancy"
              data={fetchVacancies}
            />

            <VacTypeSelect
              ref={position}
              handleSelection={setVacancyType}
              label="Select Position"
              data={fetchVacType}
            />

            <div className="usrinput">
              <h4>Upload Cover Letter *</h4>
              <input
                ref={cl}
                type="file"
                label="Upload file *"
                onChange={(e) => setSelectedCL(e.target.files[0])}
                accept=".pdf"
              />
            </div>
            <div className="usrinput">
              <h4>Upload Curriculum Vitae</h4>
              <input
                ref={cv}
                type="file"
                label="Upload file *"
                onChange={(e) => setSelectedCV(e.target.files[0])}
                accept=".pdf"
              />
            </div>
            <p>{error}</p>
            <Button handleClick={submitApplication} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
