import React, { useEffect, useState } from "react";
import "../../Styles/userhome.scss";
import GIS from "./GIS";
import Companies from "./Companies";

export default function Landing(props) {
  const [active, setActive] = useState("GIS");
 

  return (
    <div className="userhome">
      <div className="vtop">
        <Item txt="GIS" active={active} setActive={setActive} />
        <Item txt="Companies" active={active} setActive={setActive} />
      </div>
      {active === "GIS" && <GIS />}
      {active === "Companies" && <Companies />}
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className={props.active === props.txt ? "vactive" : "vitem"}
    >
      <p>{props.txt}</p>
    </div>
  );
};
