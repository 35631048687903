import {
  faBars,
  faBarsStaggered,
  faBoxes,
  faChartBar,
  faClipboard,
  faEdit,
  faGear,
  faHome,
  faMap,
  faSignOut,
  faTasks,
  faTimes,
  faUserCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import wkwp_logo from "../../assets/imgs/wkwp_logo.png";
import Loading from "./Loading";
import Button from "./Button";
import Input from "./Input";

export default function Header(props) {
  let jwt = require("jsonwebtoken");
  const [details, setDetails] = useState("");
  const [login, setLogin] = useState("");
  const [logout, setLogout] = useState("");
  const [showUserPopup, setShowUserPopup] = useState(false);
  const [role, setRole] = useState("");
  const [ID, setID] = useState("");
  const [loading, setLoading] = useState(false);
  const [userdetails, setUserDetails] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

    if (token) {
      try {
        let decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          const lg = convertTime(decoded?.iat).split("GMT")[0];
          const lo = convertTime(decoded?.exp).split("GMT")[0];

          setRole(decoded?.Role);
          setID(decoded?.UserID);
          setLogin(lg.substring(lg.length - 9, lg.length));
          setLogout(lo.substring(lo.length - 9, lo.length));
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  function convertTime(dt) {
    const date = new Date(dt * 1000);
    return date.toString();
  }
  if (role === "Company") {
    setRole("companies");
  }

  useEffect(() => {
    setLoading(true);
    fetch(`/api/${role.toLowerCase()}/fetch/${ID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setUserDetails(data[0]);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [role, ID]);

  return (
    <>
      <div className="header">
        <div className="lt">
          <FontAwesomeIcon
            className="fa-bars"
            onClick={() => setShowUserPopup(true)}
            icon={faBars}
          />
        </div>
        <p>
          <FontAwesomeIcon
            icon={faUserCircle}
            className="user"
            onClick={() => setShowUserPopup(true)}
          />
          {role === "Student" ? userdetails.StudentName : userdetails.Name}
          <span>
            <b> Login Time: </b> {login}
          </span>
          <span>
            <b> Logout Time: </b> {logout}
          </span>
          <FontAwesomeIcon
            icon={faUserCircle}
            className="user"
            onClick={() => setShowUserPopup(true)}
          />
          Update Details
        </p>
      </div>
      {showUserPopup && (
        <UpdateStudentDetails
          setShowUserPopup={setShowUserPopup}
          details={details}
          ID={ID}
          role={role}
          userDetails={userdetails}
        />
      )}
    </>
  );
}

const UpdateStudentDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [userdetails, setUserDetails] = useState("");
  const address = useRef();
  const course = useRef();
  const email = useRef();
  const phone = useRef();
  const dob = useRef();
  const university = useRef();
  const name = useRef();
  const coordName = useRef();
  const coordEmail = useRef();
  const coordUni = useRef();
  const coordDept = useRef();
  const compName = useRef();
  const compEmail = useRef();
  const compCounty = useRef();
  const compAddress = useRef();
  const compLat = useRef();
  const compLng = useRef();

  const updateStuDetails = () => {
    const body = {
      StudentPhoneNo: phone.current.value,
      StudentEmail: email.current.value.toLowerCase().trim(),
      StudentStudyArea: course.current.value,
      StudentAddress: address.current.value,
      University: university.current.value,
      StudentDOB: dob.current.value,
      StudentName: name.current.value,
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.StudentEmail)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }

      if (!body.StudentPhoneNo || body.StudentPhoneNo.length !== 10) {
        result = false;
        setError("Enter a valid phone number");
        setLoading(false);
        return result;
      }

      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/student/${props.ID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setShowUserPopup(false);
            }, 2000);
            localStorage.removeItem("userId");
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const updateCoordDetails = () => {
    const body = {
      Name: coordName.current.value,
      Email: coordEmail.current.value.toLowerCase().trim(),
      University: coordUni.current.value,
      Department: coordDept.current.value,
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/coordinator/${props.ID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setShowUserPopup(false);
            }, 2000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const updateCompDetails = () => {
    const body = {
      Name: compName.current.value,
      Email: compEmail.current.value.toLowerCase().trim(),
      County: compCounty.current.value,
      PhysicalAddress: compAddress.current.value,
      Latitude: compLat.current.value,
      Longitude: compLng.current.value,
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/companies/${props.ID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setShowUserPopup(false);
            }, 2000);
            localStorage.removeItem("userId");
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          {props.role === "Student" ? (
            <h3>
              {props?.userDetails?.StudentName}: Please Update Your Details
            </h3>
          ) : (
            <h3>{props?.userDetails?.Name}: Please Update Your Details</h3>
          )}

          <FontAwesomeIcon
            onClick={() => {
              props.setShowUserPopup(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {props?.role === "Student" && (
              <div className="">
                <Input
                  ref={name}
                  type="text"
                  label="Student Name *"
                  value={props?.userDetails?.StudentName}
                />
              </div>
            )}

            {props?.role === "Coordinator" && (
              <div className="">
                <Input
                  ref={coordName}
                  type="text"
                  label="Coordinator's Name *"
                  value={props?.userDetails?.Name}
                />
              </div>
            )}

            {props?.role === "companies" && (
              <div className="">
                <Input
                  ref={compName}
                  type="text"
                  label="Company Name *"
                  value={props?.userDetails?.Name}
                />
              </div>
            )}

            {props?.role === "Coordinator" && (
              <div className="">
                <Input
                  ref={coordEmail}
                  type="text"
                  label="Email *"
                  value={props?.userDetails?.Email}
                />
              </div>
            )}
            {props?.role === "companies" && (
              <div className="">
                <Input
                  ref={compEmail}
                  type="text"
                  label="Email *"
                  value={props?.userDetails?.Email}
                />
              </div>
            )}
            {props?.role === "Student" ? (
              <div className="div2equal">
                <Input
                  ref={university}
                  type="text"
                  label="University *"
                  value={props?.userDetails?.University}
                />
                <Input
                  ref={course}
                  type="text"
                  label="Course *"
                  value={props?.userDetails?.StudentStudyArea}
                />
              </div>
            ) : (
              props?.role === "companies" && (
                <div className="div2equal">
                  <Input
                    ref={compCounty}
                    type="text"
                    label="County *"
                    value={props?.userDetails?.County}
                  />
                  <Input
                    ref={compAddress}
                    type="text"
                    label="PhysicalAddress *"
                    value={props?.userDetails?.PhysicalAddress}
                  />
                </div>
              )
            )}
            {props?.role === "companies" && (
              <div className="div2equal">
                <Input
                  ref={compLat}
                  type="text"
                  label="Latitude *"
                  value={props?.userDetails?.Latitude}
                />
                <Input
                  ref={compLng}
                  type="text"
                  label="Longitude *"
                  value={props?.userDetails?.Longitude}
                />
              </div>
            )}

            {props?.role === "Student" && (
              <div className="div2equal">
                <Input
                  ref={address}
                  type="text"
                  label="Home Address *"
                  value={props?.userDetails?.StudentAddress}
                />
                <Input
                  ref={dob}
                  type="date"
                  label="Date of Birth *"
                  value={props?.userDetails?.StudentDOB}
                />
              </div>
            )}

            {props?.role === "Student" && (
              <div className="div2equal">
                <Input
                  ref={email}
                  type="email"
                  label="Email *"
                  value={props?.userDetails?.StudentEmail}
                />
                <Input
                  ref={phone}
                  type="number"
                  label="Phone Number *"
                  value={props?.userDetails?.StudentPhoneNo}
                />
              </div>
            )}

            {props?.role === "Coordinator" && (
              <div className="div2equal">
                <Input
                  ref={coordUni}
                  type="text"
                  label="University *"
                  value={props?.userDetails?.University}
                />
                <Input
                  ref={coordDept}
                  type="text"
                  label="Department"
                  value={props?.userDetails?.Department}
                />
              </div>
            )}

            <h6>{error}</h6>
            {props.role === "Student" ? (
              <Button handleClick={updateStuDetails} value="Submit" />
            ) : props.role === "Coordinator" ? (
              <Button handleClick={updateCoordDetails} value="Submit" />
            ) : (
              <Button handleClick={updateCompDetails} value="Submit" />
            )}
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
