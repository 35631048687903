import {
  faSearch,
  faTimes,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/beneficiary.scss";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import BeneficiaryBox from "./VacancyBox";
import SelectedVacancy from "./SelectedVacancy";

export default function Vacancies(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const selected = useRef();
  const [userID, setUserID] = useState();
  const jwt = require("jsonwebtoken");

  const [role, setRole] = useState(null);
  const [compID, setCompID] = useState(null);
  const [compName, setCompName] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");
    if (token) {
      const decoded = jwt.decode(token);

      setRole(decoded.Role);
      setCompID(decoded.UserID);
      setUserID(decoded.UserID);
      setCompName(decoded.Name);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (role === "Company") {
      fetch(`/api/vacancies/company/${compID}/${offset * 10}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          setData(data);
          setID(data?.data[0]?.ID);
          setUserDetails(data?.data[0]);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else if (role === "Student" || role === "Coordinator") {
      fetch(`/api/vacancies/paginated/${offset * 10}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          setData(data);
          setID(data?.data[0]?.ID);
          setUserDetails(data?.data[0]);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [compID, refresh, offset, role]);

  return (
    <div className="beneficiary">
      <div className="list">
        <div className="utp">
          {role === "Company" ? (
            <h3>Advertised Vacancies</h3>
          ) : (
            <h3>Available Vacancies</h3>
          )}

          {role === "Company" && (
            <p
              onClick={() => {
                setClicked(true);
              }}
            >
              <FontAwesomeIcon className="fa-add" icon={faUserPlus} /> New
              Vacancy
            </p>
          )}
        </div>
        <hr />

        {isMobile ? (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => (
                      <BeneficiaryBox
                        key={index}
                        item={item}
                        ID={ID}
                        setID={setID}
                        setUserDetails={setUserDetails}
                        selected={selected}
                      />
                    ))}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <div>
                  <h4>Vacancy Details</h4>
                  <hr />
                </div>

                {userDetails ? (
                  <SelectedVacancy
                    setLoading={setLoading}
                    beneficiaryDetails={userDetails}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    userID={userID}
                    ID={ID}
                    url="vacancies"
                    role={role}
                  />
                ) : (
                  <p>Click on a vacancy to see detailed information</p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => (
                      <BeneficiaryBox
                        key={index}
                        item={item}
                        ID={ID}
                        setID={setID}
                        setBeneficiaryDetails={setUserDetails}
                        selected={null}
                      />
                    ))}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <div>
                  <h4>Vacancy Details</h4>
                  <hr />
                </div>

                {userDetails ? (
                  <SelectedVacancy
                    setLoading={setLoading}
                    beneficiaryDetails={userDetails}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    userID={userID}
                    ID={ID}
                    url="vacancies"
                    role={role}
                  />
                ) : (
                  <p>Click on a vacancy to see detailed information</p>
                )}
              </div>
            </div>
          </div>
        )}

        {loading && <Loading />}
      </div>
      {clicked && (
        <CreateVacancy
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          userDetails={userDetails}
          compName={compName}
          compID={compID}
        />
      )}
    </div>
  );
}

const CreateVacancy = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const cpositions = ["Internship", "Attachment"];
  const title = useRef();
  const type = useRef();
  const duration = useRef();
  const requirements = useRef();
  const deadline = useRef();

  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const submitApplication = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault(); // Prevent form submission default behavior if e exists
    }

    setLoading(true);
    setError("");
    const d = {
      CompanyID: props?.compID,
      Company: props?.compName,
      Title: title.current.value,
      Type: type.current.value,
      Duration: duration.current.value,
      Requirements: requirements.current.value,
      Deadline: deadline.current.value,
    };

    fetch("/api/vacancies/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("Submission Failed");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setClicked(false);
          props.setRefresh((prev) => !prev);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props?.userDetails?.Company}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form>
            <Input type="text" ref={title} label="Vacancy Title" />
            <Select
              ref={type}
              handleSelection={() => {}}
              label="Select Vacancy"
              data={cpositions}
            />
            <Input type="text" ref={duration} label="Duration" />
            <Input type="date" ref={deadline} label="Deadline" />
            <Input type="text" ref={requirements} label="Requirements" />
            <p>{error}</p>
            <Button value="Submit" handleClick={submitApplication} />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
