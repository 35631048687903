import {
  faSearch,
  faTimes,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/beneficiary.scss";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import BeneficiaryBox from "./CompanyBox";
import SelectedCompany from "./SelectedCompany";
import Success from "../Util/ConfirmSuccess";

export default function Companies(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [ID, setID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState(null);
  const selected = useRef();
  const [showConfirm, setShowConfirm] = useState(false);
  const [userID, setUserID] = useState();
  const jwt = require("jsonwebtoken");
  const [role, setRole] = useState();
  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

  useEffect(() => {
    if (token) {
      const decoded = jwt.decode(token);
      setUserID(decoded.UserID);
      setRole(decoded.Role);
    }
  }, [token]);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/companies/paginated/${offset * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data.data.length > 0) {
          setID(data.data[0].ID);
          setBeneficiaryDetails(data.data[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [refresh, offset]);

  function quickSearch(value) {
    setData(null);
    setLoading(true);
    fetch(`/api/companies/search/${value}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data.data.length > 0) {
          setID(data.data[0].ID);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
    setClicked(false);
  };

  return (
    <div className="beneficiary">
      <div className="list">
        <div className="utp">
          <h3>Available Companies</h3>
          {/* <p
            onClick={() => {
              setClicked(true);
            }}
          >
            <FontAwesomeIcon className="fa-add" icon={faUserPlus} /> New
            Application
          </p> */}

          <div className="search">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Company Name..."
              onChange={(e) => {
                const v = e.target.value;
                if (v !== "") {
                  quickSearch(v);
                } else {
                  setRefresh(!refresh);
                }
              }}
            />
            <FontAwesomeIcon className="fa-search" icon={faSearch} />
          </div>
        </div>
        <hr />

        {isMobile ? (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => {
                      return (
                        <BeneficiaryBox
                          key={index}
                          item={item}
                          ID={ID}
                          setID={setID}
                          setBeneficiaryDetails={setBeneficiaryDetails}
                          selected={selected}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <div>
                  <h4>Company Details</h4>
                  <hr />
                </div>

                {beneficiaryDetails ? (
                  beneficiaryDetails && (
                    <SelectedCompany
                      setLoading={setLoading}
                      beneficiaryDetails={beneficiaryDetails}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      userID={userID}
                      role={role}
                      url="waterproviders"
                    />
                  )
                ) : (
                  <>
                    <p>Click on a beneficiary to see their details</p>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => {
                      return (
                        <BeneficiaryBox
                          key={index}
                          item={item}
                          ID={ID}
                          setID={setID}
                          setBeneficiaryDetails={setBeneficiaryDetails}
                          selected={null}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <div>
                  <h4>Company Details</h4>
                  <hr />
                </div>

                {beneficiaryDetails ? (
                  beneficiaryDetails && (
                    <SelectedCompany
                      setLoading={setLoading}
                      beneficiaryDetails={beneficiaryDetails}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      userID={userID}
                      role={role}
                      url="waterproviders"
                    />
                  )
                ) : (
                  <>
                    <p>Click on a beneficiary to see their details</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {loading && <Loading />}
      </div>
      {clicked && (
        <Popup
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          closeConfirm={closeConfirm}
          openConfirm={openConfirm}
          setShowConfirm={setShowConfirm}
          showConfirm={showConfirm}
        />
      )}
    </div>
  );
}

const Popup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const name = useRef();
  const type = useRef();
  const county = useRef();
  const subcounty = useRef();
  const ward = useRef();
  const village = useRef();
  const latitude = useRef();
  const longitude = useRef();
  const contactPerson = useRef();
  const contactPhone = useRef();
  const contactDesignation = useRef();

  const createBeneficiary = () => {
    const body = {
      Name: name.current.value,
      Type: type.current.value,
      County: county.current.value,
      SubCounty: subcounty.current.value,
      Ward: ward.current.value,
      Village: village.current.value,
      ContactName: contactPerson.current.value,
      ContactPhone: contactPhone.current.value,
      ContactDesignation: contactDesignation.current.value,
      Longitude: longitude.current.value,
      Latitude: latitude.current.value,
    };

    setError("");

    setLoading(true);
    fetch(`/api/waterproviders/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setShowConfirm(true);
          setTimeout(() => {
            props.setClicked(false);
            props.setRefresh(!props.refresh);
          }, 5000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>New Water Provider</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="div2equal">
              <Input ref={name} type="text" label="Name *" />
              <Select
                ref={type}
                data={["WSP", "RWE", "County", "WRUA", "CSO", "Other"]}
                label="Type *"
              />
            </div>
            <div className="div2equal">
              <Select
                ref={county}
                data={[
                  " ",
                  "Bungoma",
                  "Busia",
                  "Homabay",
                  "Kakamega",
                  "Kisii",
                  "Kisumu",
                  "Migori",
                  "Siaya",
                ]}
                label="County *"
              />
              <Input ref={subcounty} type="text" label="Sub County *" />
            </div>

            <div className="div2equal">
              <Input ref={ward} type="text" label="Ward *" />
              <Input ref={village} type="text" label="Village *" />
            </div>

            <Input
              ref={contactPerson}
              value={props?.beneficiaryDetails?.ContactName}
              type="text"
              label="Name of Contact Person"
            />
            <Input
              ref={contactPhone}
              value={props?.beneficiaryDetails?.ContactPhone}
              type="number"
              label="Phone number of Contact Person"
            />
            <Input
              ref={contactDesignation}
              value={props?.beneficiaryDetails?.ContactDesignation}
              type="text"
              label="Designation of Contact Person"
            />

            <div className="div2equal">
              <Input
                ref={latitude}
                type="number"
                label="Latitude * (use (-) sign for South)"
              />
              <Input ref={longitude} type="number" label="Longitude *" />
            </div>

            <h6>{error}</h6>
            <Button handleClick={createBeneficiary} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
      {props.showConfirm && (
        <Success closeConfirm={props.closeConfirm} action="created" />
      )}
    </div>
  );
};
