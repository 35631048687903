import React, { useEffect, useState } from "react";

const CVPDFViewer = ({ match }) => {
  const { id } = match.params;
  const [pdfURL, setPDFURL] = useState("");

  useEffect(() => {
    // Fetch CV PDF URL based on ID
    // Example: const pdfURL = fetchCVPDF(id);
    setPDFURL(`path/to/cv/${id}`);
  }, [id]);

  return (
    <div>
      <h2>Curriculum Vitae PDF</h2>
      {/* Render PDF component or embed PDF viewer */}
      <embed src={pdfURL} width="800" height="600" />
    </div>
  );
};

export default CVPDFViewer;
