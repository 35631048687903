import {
  faPencilAlt,
  faTimes,
  faUpload,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import Confirm from "../Util/Confirm";
import ConfirmSuccess from "../Util/ConfirmSuccess";

export default function SelectedVacancy(props) {
  const [clicked, setClicked] = useState(false);
  const [update, setUpdate] = useState(false);
  const [toggleBasic, setToggleBasic] = useState(false);
  const [toggleperformance, setTogglePerformance] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [kpis, setKPIs] = useState(null);
  const [active, setActive] = useState("Org Details");
  const [koffset, setKOffset] = useState(0);
  const [offset, setOffset] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    props.setLoading(true);
    console.log(props?.userID);
    fetch(`/api/student/fetch/${props?.userID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        console.log(data);
        props.setLoading(false);
        setDetails(data[0]);
      })
      .catch((error) => {
        props.setLoading(false);
      });
  }, []);

  const deleteVacancy = () => {
    props.setLoading(true);
    fetch(`/api/vacancies/${props?.beneficiaryDetails?.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        if (data.success) {
          setError(data.success);

          setTimeout(() => {
            window.location.href = "/vacancies";
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((e) => {
        props.setLoading(false);
      });
  };

  return (
    <div className="sbeneficiary">
      <div>
        <p>Company: {props?.beneficiaryDetails?.Company}</p>
        <p>Title: {props?.beneficiaryDetails?.Title}</p>
        <p>Type: {props?.beneficiaryDetails?.Type}</p>
        <p>Duration: {props?.beneficiaryDetails?.Duration}</p>
        <p>Deadline: {props?.beneficiaryDetails?.Deadline}</p>
        <hr />
        <p>Requirements: {props?.beneficiaryDetails?.Requirements}</p>
      </div>
      <hr />
      {props.role === "Company" ? (
        <div className="data">
          <div className="bars">
            <h4
              onClick={() => {
                setUpdate(true);
              }}
              className={active === "Update" ? "active" : ""}
            >
              <FontAwesomeIcon className="fa" icon={faPencilAlt} />
              Update
            </h4>
            <h4
              onClick={() => {
                deleteVacancy();
              }}
              className={active === "Delete" ? "active" : ""}
            >
              <FontAwesomeIcon className="fa" icon={faTrash} />
              Delete
            </h4>
          </div>
        </div>
      ) : (
        <div className="data">
          <div className="bars">
            <h4
              onClick={() => {
                setClicked(true);
              }}
              className={active === "Apply" ? "active" : ""}
            >
              <FontAwesomeIcon className="fa" icon={faPencilAlt} />
              Apply
            </h4>
          </div>
        </div>
      )}

      {clicked && (
        <MakeApplication
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          details={details}
          beneficiaryDetails={props.beneficiaryDetails}
        />
      )}
      {update && (
        <UpdateVacancy
          setClicked={setUpdate}
          setRefresh={setRefresh}
          refresh={refresh}
          details={details}
          beneficiaryDetails={props.beneficiaryDetails}
        />
      )}
    </div>
  );
}

const MakeApplication = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedCL, setSelectedCL] = useState(null);
  const [selectedCV, setSelectedCV] = useState(null);
  const cpositions = ["Internship", "Attachment"];
  const companies = ["Company1", "Company2"];

  const cl = useRef();
  const cv = useRef();
  const position = useRef();
  const vacancy = useRef();

  console.log(props?.beneficiaryDetails?.CompanyID);

  const [body, updateBody] = useState({
    StudentName: null,
    StudentID: null,
    StudentStudyArea: null,
    CompanyID: null,
    StudentEmail: null,
    CompanyName: null,
    Course: null,
    Position: null,
    Vacancy: null,
    CL: null,
    CV: null,
  });

  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const submitApplication = (e) => {
    setError("");
    let d = body;
    d.StudentName = props.details?.StudentName;
    d.StudentEmail = props.details?.StudentEmail;
    d.StudentID = props.details?.StudentID;
    d.CompanyID = props.beneficiaryDetails?.CompanyID;
    d.CL = cl.current.files[0];
    d.CV = cv.current.files[0];
    d.Course = props.details?.StudentStudyArea;
    d.CompanyName = props.beneficiaryDetails?.Company;
    d.Vacancy = props?.beneficiaryDetails?.Title;
    d.Position = props?.beneficiaryDetails?.Type;

    updateBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    if (d.CV === undefined) return setError("CV is required!");
    setLoading(true);

    const formData = new FormData();

    for (const i in body) {
      formData.append(i, body[i]);
    }

    fetch("/api/applications/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setClicked(false);
          window.location.href = "/applications";
          setTimeout(() => {}, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>Company: {props?.beneficiaryDetails?.Company}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Input
              type="text"
              ref={position}
              label="Position"
              value={props?.beneficiaryDetails?.Title}
            />
            <Input
              type="text"
              ref={vacancy}
              label="Type"
              value={props?.beneficiaryDetails?.Type}
            />
            <div className="usrinput">
              <h4>Upload Cover Letter *</h4>
              <input
                ref={cl}
                type="file"
                label="Upload file *"
                onChange={(e) => setSelectedCL(e.target.files[0])}
                accept=".pdf"
              />
            </div>
            <div className="usrinput">
              <h4>Upload Curriculum Vitae</h4>
              <input
                ref={cv}
                type="file"
                label="Upload file *"
                onChange={(e) => setSelectedCV(e.target.files[0])}
                accept=".pdf"
              />
            </div>

            <p>{error}</p>
            <Button handleClick={submitApplication} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};

const UpdateVacancy = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const cpositions = ["Internship", "Attachment"];
  const title = useRef();
  const type = useRef();
  const duration = useRef();
  const requirements = useRef();
  const deadline = useRef();

  const setType = (value) => {
    // setCompanies(value)
  };

  const [body, updateBody] = useState({
    Company: null,
    Title: null,
    Type: null,
    Duration: null,
    Requirements: null,
    Deadline: null,
  });

  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const submitApplication = (e) => {
    setLoading(true);
    setError("");
    let d = body;
    d.Company = props?.beneficiaryDetails?.Company;
    d.Title = title.current.value;
    d.Type = type.current.value;
    d.Duration = duration.current.value;
    d.Requirements = requirements.current.value;
    d.Deadline = deadline.current.value;
    updateBody(d);

    fetch(`/api/vacancies/${props?.beneficiaryDetails?.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("Submission Failed");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setClicked(false);
          setTimeout(() => {}, 1000);
          window.location.href = "/vacancies";
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props?.beneficiaryDetails?.Title}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Input
              type="text"
              ref={title}
              label="Vacancy Title"
              value={props?.beneficiaryDetails?.Title}
            />

            <Select
              ref={type}
              handleSelection={setType}
              label="Select Vacancy"
              data={cpositions}
              value={props?.beneficiaryDetails?.Type}
            />

            <Input
              type="text"
              ref={duration}
              label="Duration"
              value={props?.beneficiaryDetails?.Duration}
            />

            <Input
              type="date"
              ref={deadline}
              label="Deadline"
              value={props?.beneficiaryDetails?.Deadline}
            />

            <Input
              type="text"
              ref={requirements}
              label="Requirements"
              value={props?.beneficiaryDetails?.Requirements}
            />

            {error && <p>{String(error)}</p>}
            <Button handleClick={submitApplication} value="Update" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
