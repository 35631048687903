import "../Styles/home.scss";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState, useEffect, useRef } from "react";
import Settings from "../components/Settings/Settings";
import CountyAll from "../components/GIS/GIS";
import Vacancies from "../components/Vacancies/Vacancies";
import Landing from "../components/GIS/Landing";
import MyApplications from "../components/Applications/MyApplications";
import Placements from "../components/Placements/Placements";
import Reviews from "../components/Reviews/Reviews";
import Loading from "../components/Util/Loading";
import Button from "../components/Util/Button";
import Input from "../components/Util/Input";
import Select from "../components/Util/Select";
import {
  faDownload,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StudentApplications from "../components/StudentApplications/StudentApplications";
import MyReviews from "../components/MyReviews/MyReviews";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");
  const [shownavigation, setShowNavigation] = useState(false);
  const [role, setRole] = useState();
  const [lat, setLat] = useState();
  const [department, setDepartment] = useState();
  const [course, setCourse] = useState();
  const [updateCompany, setUpdateCompany] = useState(false);
  const [updateCoordinator, setUpdateCoordinator] = useState(false);
  const [updateStudent, setUpdateStudent] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [table, setTable] = useState();
  const [userName, setUserName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const jwt = require("jsonwebtoken");
  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

  useEffect(() => {
    if (token) {
      let decoded = jwt.decode(token);
      localStorage.setItem("userId", decoded.UserID);
      setRole(decoded.Role);
      setUserName(decoded.Name);
      setPhone(decoded.Phone);
      setEmail(decoded.Email);
    }
  }, [token]);

  let userId = localStorage.getItem("userId");

  useEffect(() => {
    if (role === "Company") {
      setTable("companies");
    } else if (role === "Student") {
      setTable("student");
    } else if (role === "Coordinator") {
      setTable("coordinator");
    }
  }, [role]);

  useEffect(() => {
    if (table) {
      fetch(`/api/${table}/fetch/${userId}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          if (data.length > 0) {
            const firstData = data[0]; // Get the first element of the data array

            // Check if the data has the StudentStudyArea column
            if ("StudentStudyArea" in firstData) {
              setCourse(firstData.StudentStudyArea);
              if (course === null) {
                setUpdateStudent(true);
              } else {
                setUpdateStudent(false);
              }
            }

            // Check if the data has the Latitude column

            if ("Latitude" in firstData) {
              setLat(firstData.Latitude);
              if (lat === null) {
                setUpdateCompany(true);
              } else {
                setUpdateCompany(false);
              }
            }

            // Check if the data has the Department column
            if ("Department" in firstData) {
              setDepartment(firstData.Department);
              if (department === null) {
                setUpdateCoordinator(true);
              } else {
                setUpdateCoordinator(false);
              }
            }
          }
        })
        .catch((error) => {});
    }
  }, [userId, table, course, department, lat]);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "225px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation
            showing={showing}
            setShowing={setShowing}
            shownavigation={shownavigation}
            role={role}
          />
        </div>
        <div className="right_panel">
          <Header
            showing={showing}
            setShowing={setShowing}
            setShowNavigation={setShowNavigation}
            shownavigation={setShowNavigation}
          />

          <div className="full">
            {pathname[1] === "" && <Landing showing={showing} />}

            {pathname[1] === "applications" && pathname.length == 2 && (
              <MyApplications />
            )}
            {pathname[1] === "vacancies" && pathname.length == 2 && (
              <Vacancies />
            )}

            {pathname[1] === "studentapplications" && pathname.length == 2 && (
              <StudentApplications />
            )}
            {pathname[1] === "placements" && pathname.length == 2 && (
              <Placements />
            )}
            {pathname[1] === "reviews" && pathname.length == 2 && <Reviews />}

            {pathname[1] === "myreviews" && pathname.length == 2 && (
              <MyReviews />
            )}

            {pathname[1] === "gis" && pathname.length === 2 && (
              <CountyAll showing={showing} />
            )}

            {pathname[1] === "settings" && <Settings />}

            {updateCompany && (
              <UpdateCompanyDetails
                setClicked={setUpdateCompany}
                setRefresh={setRefresh}
                refresh={refresh}
                userName={userName}
                Email={email}
                Phone={phone}
                userId={userId}
              />
            )}
            {updateCoordinator && (
              <UpdateCoordinatorDetails
                setClicked={setUpdateCoordinator}
                setRefresh={setRefresh}
                refresh={refresh}
                userName={userName}
                Email={email}
                Phone={phone}
                userId={userId}
              />
            )}
            {updateStudent && (
              <UpdateStudentDetails
                setClicked={setUpdateStudent}
                setRefresh={setRefresh}
                refresh={refresh}
                userName={userName}
                Email={email}
                Phone={phone}
                userId={userId}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const UpdateCompanyDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const name = useRef();
  const email = useRef();
  const county = useRef();
  const address = useRef();
  const latitude = useRef();
  const longitude = useRef();

  const createUser = () => {
    const body = {
      Name: name.current.value,
      Email: email.current.value.toLowerCase().trim(),
      County: county.current.value,
      PhysicalAddress: address.current.value,
      Latitude: latitude.current.value,
      Longitude: longitude.current.value,
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }

      if (!body.Latitude) {
        result = false;
        setError("Enter the Latitude Coordinate");
        setLoading(false);
        return result;
      }

      if (!body.Longitude) {
        result = false;
        setError("Enter the Longitude Coordinate");
        setLoading(false);
        return result;
      }

      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/companies/${props.userId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setClicked(false);
              props.setRefresh(!props.refresh);
            }, 2000);
            localStorage.removeItem("userId");
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props.userName}: Please Update Your Details</h3>
          {/* <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          /> */}
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="">
              <Input
                ref={name}
                type="text"
                label="Company Name *"
                value={props.userName}
              />
            </div>
            <div className="div2equal">
              <Input ref={county} type="text" label="County of Location *" />
              <Input ref={address} type="text" label="Physical Address *" />
            </div>
            <div className="div2equal">
              <Input ref={latitude} type="number" label="Latitude *" />
              <Input ref={longitude} type="number" label="Longitude *" />
            </div>

            <div className="">
              <Input
                ref={email}
                type="email"
                label="Email *"
                value={props.Email}
              />
            </div>

            <h6>{error}</h6>
            <Button handleClick={createUser} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};

const UpdateCoordinatorDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const name = useRef();
  const email = useRef();
  const university = useRef();
  const dpt = useRef();

  const createUser = () => {
    const body = {
      Name: name.current.value,
      Email: email.current.value.toLowerCase().trim(),
      University: university.current.value,
      Department: dpt.current.value,
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }

      if (!body.University) {
        result = false;
        setError("Enter your University Name");
        setLoading(false);
        return result;
      }

      if (!body.Department) {
        result = false;
        setError("Enter your Department");
        setLoading(false);
        return result;
      }

      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/coordinator/${props.userId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setClicked(false);
              props.setRefresh(!props.refresh);
            }, 2000);
            localStorage.removeItem("userId");
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props.userName}: Please Update Your Details</h3>
          {/* <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          /> */}
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="div2equal">
              <Input
                ref={name}
                type="text"
                label="Coorinator's Name *"
                value={props.userName}
              />
              <Input
                ref={email}
                type="email"
                label="Email *"
                value={props.Email}
              />
            </div>
            <div className="div2equal">
              <Input ref={university} type="text" label="University *" />
              <Input ref={dpt} type="text" label="Department *" />
            </div>

            <h6>{error}</h6>
            <Button handleClick={createUser} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};

const UpdateStudentDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const address = useRef();
  const course = useRef();
  const email = useRef();
  const phone = useRef();
  const dob = useRef();
  const university = useRef();
  const name = useRef();

  const createUser = () => {
    const body = {
      StudentPhoneNo: phone.current.value,
      StudentEmail: email.current.value.toLowerCase().trim(),
      StudentStudyArea: course.current.value,
      StudentAddress: address.current.value,
      University: university.current.value,
      StudentDOB: dob.current.value,
      StudentName: name.current.value,
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.StudentEmail)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }

      if (!body.StudentPhoneNo || body.StudentPhoneNo.length !== 10) {
        result = false;
        setError("Enter a valid phone number");
        setLoading(false);
        return result;
      }

      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/student/${props.userId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setClicked(false);
              props.setRefresh(!props.refresh);
            }, 2000);
            localStorage.removeItem("userId");
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props.userName}: Please Update Your Details</h3>
          {/* <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          /> */}
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="">
              <Input
                ref={name}
                type="text"
                label="Student Name *"
                value={props.userName}
              />
            </div>
            <div className="div2equal">
              <Input ref={university} type="text" label="University *" />
              <Input ref={course} type="text" label="Course *" />
            </div>
            <div className="div2equal">
              <Input ref={address} type="text" label="Home Address *" />
              <Input ref={dob} type="date" label="Date of Birth *" />
            </div>

            <div className="div2equal">
              <Input
                ref={email}
                type="email"
                label="Email *"
                value={props.Email}
              />
              <Input
                ref={phone}
                type="number"
                label="Phone Number *"
                value={props.Phone}
              />
            </div>

            <h6>{error}</h6>
            <Button handleClick={createUser} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
