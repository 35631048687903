import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import NotFound from "./Pages/404";
import Login from "./Pages/LoginNew";
import Home from "./Pages/Home";
import CLPDFViewer from "./components/Applications/CLViewer";
import CVPDFViewer from "./components/Applications/CVViewer";
import ProtectedRoute from "./components/Login/ProtectedRoute";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/cl/:id" element={<CLPDFViewer />} />
        <Route path="/cv/:id" element={<CVPDFViewer />} />
        <Route path="/*" element={<ProtectedRoute component={Home} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
