import { useEffect, useState } from "react";

export default function CompanyBox(props) {
  const [color, setColor] = useState("orange");

  return (
    <div
      className={props.ID === props.item.ID ? "user-box active" : "user-box"}
      onClick={() => {
        props.setID(props?.item?.ID);
        props.setBeneficiaryDetails(props?.item);
        props?.selected?.current.scrollIntoView({ behavior: "smooth" });
      }}
    >
      <div className="left">
        <h2 style={{ backgroundColor: color }}>{props?.item?.Type}</h2>
      </div>
      <div className="right">
        <h3 className="title">Name: {props?.item?.Name}</h3>
        <p className="dark">
          County:
          {props?.item?.County}
        </p>
      </div>
    </div>
  );
}
