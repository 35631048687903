import {
  faSearch,
  faTimes,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/beneficiary.scss";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import ApplicationBox from "./ApplicationBox";
import SelectedApplication from "./SelectedApplication";
import Success from "../Util/ConfirmSuccess";
import CompaniesSelect from "../Util/CompaniesSelect";
import VacanciesSelect from "../Util/VacanciesSelect";
import VacTypeSelect from "../Util/VacTypeSelect";

export default function MyApplications(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [ID, setID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(null);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState(null);
  const selected = useRef();
  const [showConfirm, setShowConfirm] = useState(false);
  const jwt = require("jsonwebtoken");
  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");
  const [role, setRole] = useState();

  useEffect(() => {
    if (token) {
      const decoded = jwt.decode(token);
      setDetails(decoded);
      setUserID(decoded.UserID);
      setRole(decoded.Role);
    }
  }, [token]);

  useEffect(() => {
    const fetchApplications = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");
        if (!token) {
          throw new Error("Token not found.");
        }
        const decoded = jwt.decode(token);
        setUserID(decoded.UserID);

        const response = await fetch(
          `/api/applications/students/${decoded.UserID}/${offset * 12}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data.");
        }
        const data = await response.json();
        setData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [offset]);

  return (
    <div className="beneficiary">
      <div className="list">
        <div className="utp">
          <h3>My Applications</h3>
        </div>
        <hr />

        {isMobile ? (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => {
                      return (
                        <ApplicationBox
                          key={index}
                          item={item}
                          ID={ID}
                          setID={setID}
                          setBeneficiaryDetails={setBeneficiaryDetails}
                          selected={selected}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <div>
                  <h4>Application Details</h4>
                  <hr />
                </div>

                {beneficiaryDetails ? (
                  beneficiaryDetails && (
                    <SelectedApplication
                      setLoading={setLoading}
                      beneficiaryDetails={beneficiaryDetails}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      role={role}
                      url="waterproviders"
                    />
                  )
                ) : (
                  <>
                    <p>Click on an application to see detailed information</p>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.data?.length > 0 &&
                    data?.data?.map((item, index) => {
                      return (
                        <ApplicationBox
                          key={index}
                          item={item}
                          ID={ID}
                          setID={setID}
                          setBeneficiaryDetails={setBeneficiaryDetails}
                          selected={null}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <div>
                  <h4>Application Details</h4>
                  <hr />
                </div>

                {beneficiaryDetails ? (
                  beneficiaryDetails && (
                    <SelectedApplication
                      setLoading={setLoading}
                      beneficiaryDetails={beneficiaryDetails}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      url="waterproviders"
                      role={role}
                    />
                  )
                ) : (
                  <>
                    <p>Click on an application to see detailed information</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {loading && <Loading />}
      </div>
    </div>
  );
}
